import { EContentType } from "../enum/content-type.enum";
import { IApiResultTYpe } from "../interfaces/iMarketWizard/iMWizard.interface";
import {
  IActMWizardAnalyticsFail01,
  IActMWizardAnalyticsFail02,
  IActMWizardAnalyticsFail03,
  IActMWizardAnalyticsReqeust01,
  IActMWizardAnalyticsReqeust02,
  IActMWizardAnalyticsReqeust03,
  IActMWizardAnalyticsSucc01,
  IActMWizardAnalyticsSucc02,
  IActMWizardAnalyticsSucc03,
  IActMWizardInitAnalytics01,
  IActMWizardInitMsgAnalytics01,
  IActMWizardPublishContentsDaily,
  IActMWizardPublishContentsDailyFail,
  IActMWizardPublishContentsDailySucc,
  IActMWizardPublishContentsWeekly,
  IActMWizardPublishContentsWeeklyFail,
  IActMWizardPublishContentsWeeklySucc,
  IActMWizardType,
} from "../interfaces/iMarketWizard/iMWizardAction.interface";

// weekly - 분석 아이템 리스트 request
export function actRequestPublishContentsWeekly(
  token: string
): IActMWizardPublishContentsWeekly {
  return {
    type: IActMWizardType.REQUEST_PUBLISH_CONTENTS_WEEKLY,
    token,
  };
}
// weekly - 분석 아이템 리스트 성공
export function actPublishContentsWeeklySucc(
  publishContentsWeeklySuccMsg: string,
  publishContentsWeeklyData: any
): IActMWizardPublishContentsWeeklySucc {
  return {
    type: IActMWizardType.PUBLISH_CONTENTS_WEEKLY_SUCC,
    publishContentsWeeklySuccMsg,
    publishContentsWeeklyData,
  };
}
// weekly - 분석 아이템 리스트 실패
export function actPublishContentsWeeklyFail(
  publishContentsWeeklyFailMsg: string
): IActMWizardPublishContentsWeeklyFail {
  return {
    type: IActMWizardType.PUBLISH_CONTENTS_WEEKLY_FAIL,
    publishContentsWeeklyFailMsg,
  };
}

// Daily - 아이템 추천 리스트 request
export function actRequestPublishContentsDaily(
  token: string
): IActMWizardPublishContentsDaily {
  return {
    type: IActMWizardType.REQUEST_PUBLISH_CONTENTS_DAILY,
    token,
  };
}
// Daily - 아이템 추천 리스트 성공
export function actPublishContentsDailySucc(
  publishContentsDailySuccMsg: string,
  publishContentsDailyData: any
): IActMWizardPublishContentsDailySucc {
  return {
    type: IActMWizardType.PUBLISH_CONTENTS_DAILY_SUCC,
    publishContentsDailySuccMsg,
    publishContentsDailyData,
  };
}
// Daily - 아이템 추천 리스트 실패
export function actPublishContentsDailyFail(
  publishContentsDailyFailMsg: string
): IActMWizardPublishContentsDailyFail {
  return {
    type: IActMWizardType.PUBLISH_CONTENTS_DAILY_FAIL,
    publishContentsDailyFailMsg,
  };
}

// 분석_01 api 초기화
export function actInitAnalytics01(): IActMWizardInitAnalytics01 {
  return {
    type: IActMWizardType.INIT_ANALYTICS_01,
  };
}
// 분석_01 api 메시지 초기화
export function actInitMsgAnalytics01(): IActMWizardInitMsgAnalytics01 {
  return {
    type: IActMWizardType.INIT_MSG_ANALYTICS_01,
  };
}
// 분석_01 api 요청
export function actAnalyticsRequest01(
  token: string,
  analyticsId: number,
  cafe24Idx: number,
  contentType?: EContentType
): IActMWizardAnalyticsReqeust01 {
  return {
    type: IActMWizardType.REQUEST_ANALYTICS_01,
    token,
    analyticsId,
    cafe24Idx,
    contentType,
  };
}
// 분석_01 api 요청 성공
export function actAnalyticsSucc01(
  analyticsSuccMsg01: string,
  analyticsData01: IApiResultTYpe
): IActMWizardAnalyticsSucc01 {
  return {
    type: IActMWizardType.ANALYTICS_SUCC_01,
    analyticsSuccMsg01,
    analyticsData01,
  };
}
// 분석_01 api 요청 실패
export function actAnalyticsFail01(
  analyticsFailMsg01: string
): IActMWizardAnalyticsFail01 {
  return {
    type: IActMWizardType.ANALYTICS_FAIL_01,
    analyticsFailMsg01,
  };
}

// 분석_02 api 요청
export function actAnalyticsRequest02(
  token: string,
  analyticsId: number
): IActMWizardAnalyticsReqeust02 {
  return {
    type: IActMWizardType.REQUEST_ANALYTICS_02,
    token,
    analyticsId,
  };
}
// 분석_02 api 요청 성공
export function actAnalyticsSucc02(
  analyticsSuccMsg02: string,
  analyticsData02: IApiResultTYpe
): IActMWizardAnalyticsSucc02 {
  return {
    type: IActMWizardType.ANALYTICS_SUCC_02,
    analyticsSuccMsg02,
    analyticsData02,
  };
}
// 분석_02 api 요청 실패
export function actAnalyticsFail02(
  analyticsFailMsg02: string
): IActMWizardAnalyticsFail02 {
  return {
    type: IActMWizardType.ANALYTICS_FAIL_02,
    analyticsFailMsg02,
  };
}

// 분석_03 api 요청
export function actAnalyticsRequest03(
  token: string,
  analyticsId: number
): IActMWizardAnalyticsReqeust03 {
  return {
    type: IActMWizardType.REQUEST_ANALYTICS_03,
    token,
    analyticsId,
  };
}
// 분석_03 api 요청 성공
export function actAnalyticsSucc03(
  analyticsSuccMsg03: string,
  analyticsData03: IApiResultTYpe
): IActMWizardAnalyticsSucc03 {
  return {
    type: IActMWizardType.ANALYTICS_SUCC_03,
    analyticsSuccMsg03,
    analyticsData03,
  };
}
// 분석_03 api 요청 실패
export function actAnalyticsFail03(
  analyticsFailMsg03: string
): IActMWizardAnalyticsFail03 {
  return {
    type: IActMWizardType.ANALYTICS_FAIL_03,
    analyticsFailMsg03,
  };
}
