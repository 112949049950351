import { EContentType } from "../../enum/content-type.enum";
import { IApiResultTYpe } from "./iMWizard.interface";

export enum IActMWizardType {
  REQUEST_PUBLISH_CONTENTS_WEEKLY = "REQUEST_PUBLISH_CONTENTS_WEEKLY", // weekly - 분석 아이템 리스트
  PUBLISH_CONTENTS_WEEKLY_SUCC = "PUBLISH_CONTENTS_WEEKLY_SUCC", // weekly - 분석 아이템 리스트 성공
  PUBLISH_CONTENTS_WEEKLY_FAIL = "PUBLISH_CONTENTS_WEEKLY_FAIL", // weekly - 분석 아이템 리스트 실패

  REQUEST_PUBLISH_CONTENTS_DAILY = "REQUEST_PUBLISH_CONTENTS_DAILY", // DAILY - 분석 아이템 리스트
  PUBLISH_CONTENTS_DAILY_SUCC = "PUBLISH_CONTENTS_DAILY_SUCC", // DAILY - 분석 아이템 리스트 성공
  PUBLISH_CONTENTS_DAILY_FAIL = "PUBLISH_CONTENTS_DAILY_FAIL", // DAILY - 분석 아이템 리스트 실패

  INIT_ANALYTICS_01 = "INIT_ANALYTICS_01", // 분석_01 api 초기화
  INIT_MSG_ANALYTICS_01 = "INIT_MSG_ANALYTICS_01", // 분석_01 api 메시지 초기화
  REQUEST_ANALYTICS_01 = "REQUEST_ANALYTICS_01", // 분석_01 api 요청
  ANALYTICS_SUCC_01 = "ANALYTICS_SUCC_01", // 분석_01 api 요청 성공
  ANALYTICS_FAIL_01 = "ANALYTICS_FAIL_01", // 분석_01 api 요청 실패
  REQUEST_ANALYTICS_02 = "REQUEST_ANALYTICS_02", // 분석_02 api 요청
  ANALYTICS_SUCC_02 = "ANALYTICS_SUCC_02", // 분석_02 api 요청 성공
  ANALYTICS_FAIL_02 = "ANALYTICS_FAIL_02", // 분석_02 api 요청 실패
  REQUEST_ANALYTICS_03 = "REQUEST_ANALYTICS_03", // 분석_03 api 요청
  ANALYTICS_SUCC_03 = "ANALYTICS_SUCC_03", // 분석_03 api 요청 성공
  ANALYTICS_FAIL_03 = "ANALYTICS_FAIL_03", // 분석_03 api 요청 실패
}

export type IActMWizard =
  | IActMWizardPublishContentsWeekly
  | IActMWizardPublishContentsWeeklySucc
  | IActMWizardPublishContentsWeeklyFail
  | IActMWizardPublishContentsDaily
  | IActMWizardPublishContentsDailySucc
  | IActMWizardPublishContentsDailyFail
  | IActMWizardInitAnalytics01
  | IActMWizardInitMsgAnalytics01
  | IActMWizardAnalyticsReqeust01
  | IActMWizardAnalyticsSucc01
  | IActMWizardAnalyticsFail01
  | IActMWizardAnalyticsReqeust02
  | IActMWizardAnalyticsSucc02
  | IActMWizardAnalyticsFail02
  | IActMWizardAnalyticsReqeust03
  | IActMWizardAnalyticsSucc03
  | IActMWizardAnalyticsFail03;

// publish_contents/weekly
export interface IActMWizardPublishContentsWeekly {
  type: IActMWizardType.REQUEST_PUBLISH_CONTENTS_WEEKLY;
  token: string;
}
// publish_contents/weekly 성공
export interface IActMWizardPublishContentsWeeklySucc {
  type: IActMWizardType.PUBLISH_CONTENTS_WEEKLY_SUCC;
  publishContentsWeeklySuccMsg: string;
  publishContentsWeeklyData: IApiResultTYpe;
}
// publish_contents/weekly 실패
export interface IActMWizardPublishContentsWeeklyFail {
  type: IActMWizardType.PUBLISH_CONTENTS_WEEKLY_FAIL;
  publishContentsWeeklyFailMsg: string;
}

// publish_contents/daily
export interface IActMWizardPublishContentsDaily {
  type: IActMWizardType.REQUEST_PUBLISH_CONTENTS_DAILY;
  token: string;
}
// publish_contents/daily 성공
export interface IActMWizardPublishContentsDailySucc {
  type: IActMWizardType.PUBLISH_CONTENTS_DAILY_SUCC;
  publishContentsDailySuccMsg: string;
  publishContentsDailyData: IApiResultTYpe;
}
// publish_contents/daily 실패
export interface IActMWizardPublishContentsDailyFail {
  type: IActMWizardType.PUBLISH_CONTENTS_DAILY_FAIL;
  publishContentsDailyFailMsg: string;
}

// analytics/:analyticsId 01 초기화
export interface IActMWizardInitAnalytics01 {
  type: IActMWizardType.INIT_ANALYTICS_01;
}
// analytics/:analyticsId 01 메시지 초기화
export interface IActMWizardInitMsgAnalytics01 {
  type: IActMWizardType.INIT_MSG_ANALYTICS_01;
}
// analytics/:analyticsId 01
export interface IActMWizardAnalyticsReqeust01 {
  type: IActMWizardType.REQUEST_ANALYTICS_01;
  token: string;
  analyticsId: number;
  cafe24Idx: number;
  contentType?: EContentType;
}
// analytics/:analyticsId 성공 01
export interface IActMWizardAnalyticsSucc01 {
  type: IActMWizardType.ANALYTICS_SUCC_01;
  analyticsSuccMsg01: string;
  analyticsData01: IApiResultTYpe;
}
// analytics/:analyticsId 실패 01
export interface IActMWizardAnalyticsFail01 {
  type: IActMWizardType.ANALYTICS_FAIL_01;
  analyticsFailMsg01: string;
}

// analytics/:analyticsId 02
export interface IActMWizardAnalyticsReqeust02 {
  type: IActMWizardType.REQUEST_ANALYTICS_02;
  token: string;
  analyticsId: number;
}
// analytics/:analyticsId 성공 02
export interface IActMWizardAnalyticsSucc02 {
  type: IActMWizardType.ANALYTICS_SUCC_02;
  analyticsSuccMsg02: string;
  analyticsData02: IApiResultTYpe;
}
// analytics/:analyticsId 실패 02
export interface IActMWizardAnalyticsFail02 {
  type: IActMWizardType.ANALYTICS_FAIL_02;
  analyticsFailMsg02: string;
}

// analytics/:analyticsId 03
export interface IActMWizardAnalyticsReqeust03 {
  type: IActMWizardType.REQUEST_ANALYTICS_03;
  token: string;
  analyticsId: number;
}
// analytics/:analyticsId 성공 03
export interface IActMWizardAnalyticsSucc03 {
  type: IActMWizardType.ANALYTICS_SUCC_03;
  analyticsSuccMsg03: string;
  analyticsData03: IApiResultTYpe;
}
// analytics/:analyticsId 실패 01
export interface IActMWizardAnalyticsFail03 {
  type: IActMWizardType.ANALYTICS_FAIL_03;
  analyticsFailMsg03: string;
}
