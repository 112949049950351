export const setCookie = (key: string, value: string, maxAge?: number): boolean => {
    try {
        if (document) {
            const encodeValue = escape(value);
            let ck = `${key}=${encodeValue};path=/;`;
            if (maxAge) {
                ck = ck + `max-age=${maxAge};`;
            }
            document.cookie = ck;
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log('try catch', error);
        return false;
    }
}

export const removeCookie = (key: string): boolean => {
    try {
        if (document) {
            document.cookie = `${key}=;path=/;max-age=0;`;
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log('try catch', error);
        return false;
    }
}

export const getCookie = (key: string): string => {
    try {
        if (document) {
            const value = document.cookie.match(`(^|;) ?${key}=([^;]*)(;|$)`);
            return value ? unescape(value[2]) : '';
        } else {
            return '';
        }
    } catch (error) {
        console.log('try catch', error);
        return '';
    }
}
